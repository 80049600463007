import * as React from "react"
import styled from "styled-components"

import Layout from "../components/theme/layout"
import Seo from "../components/seo"

const PageWrapper = styled.div`
  height: 54vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 900px) {
    height: 60vh;
  }
`

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <PageWrapper>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </PageWrapper>
      </Layout>
)

export default NotFoundPage
